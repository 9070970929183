import React, {useCallback, useEffect, useState} from 'react'
import {Table} from 'antd'
import b_ from 'b_'
import TableSettings from "../../Components/TableSettings"
import useStorageState from '../../Utils/useStorageState'
import AddEditCard from "./AddEditCard";
import LockCardSwitch from './LockCardSwitch'

const b = b_.lock('CardsTable')

function CardsTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      sorter,
                      onFilter,
                      filters,
                      selected,
                      onSelectChange,
                      platformsMap,
                    }) {

  const platforms = platformsMap && Object.keys(platformsMap).sort()
    .map(p => {
      return {text: p, value: p}
    })

  const groups = platformsMap &&
    Array.from(new Set(Object.values(platformsMap)
      .flatMap(p => p.groupCurrency)
      .map(gc => gc.group))).sort()
      .map(p => {
        return {text: p, value: p}
      })

  const currencies = platformsMap &&
    Array.from(new Set(Object.values(platformsMap)
      .flatMap((p) => p.groupCurrency)
      .map(gc => gc.currency))).sort()
      .map(p => {
        return {text: p, value: p}
      })

  const GetColumns = useCallback(() => {

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        sorter,
        sortOrder: sorter.field === 'id' ? sorter.order : null,
      },
      {
        title: 'Platform',
        dataIndex: 'platform',
        key: 'platform',
        width: 100,
        sorter,
        sortOrder: sorter.field === 'platform' ? sorter.order : null,
        filters: platforms,
        filteredValue: filters['platform'] || null,
        filterMultiple: false,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        sorter,
        sortOrder: sorter.field === 'name' ? sorter.order : null,
      },
      {
        title: 'Group',
        dataIndex: 'group',
        key: 'group',
        width: 100,
        sorter,
        sortOrder: sorter.field === 'group' ? sorter.order : null,
        filters: groups,
        filteredValue: filters['group'] || null,
        filterMultiple: false,
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        align: 'right',
        width: 100
      },
      {
        title: 'Base Price',
        dataIndex: 'basePrice',
        key: 'basePrice',
        align: 'right',
        width: 100,
        sorter,
        sortOrder: sorter.field === 'basePrice' ? sorter.order : null,
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        width: 100,
        sorter,
        sortOrder: sorter.field === 'currency' ? sorter.order : null,
        filters: currencies,
        filteredValue: filters['currency'] || null,
        filterMultiple: false,
      },
      {
        title: 'Update',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        render: (_, record) => record.id &&
          <div className="actionButtonsGroup">
            <AddEditCard add={false} afterUpdate={afterUpdate} platforms={platformsMap} rec={record}/>
          </div>
      },
      {
        title: 'Lock Card',
        dataIndex: 'locked',
        key: 'locked',
        width: 50,
        render: (_, record) => record.id &&
          <div className="actionButtonsGroup">
            <LockCardSwitch rec={record} locked={record.locked} id={record.id} afterUpdate={afterUpdate} />
          </div>
      },
    ];
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('cards_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < columns.length,
      checkAll: checkedList.length === columns.length,
    });
  }, [Checkboxs]);

  useEffect(() => {
    let allCheckList = []
    if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
        allCheckList.push(e[Object.keys(e)[1]])
        setCheckbox(allCheckList)
      })
    }
  }, [list, AllCheckbox, Checkboxs.length, GetColumns])

  useEffect(() => {
    if (AllCheckbox.checkAll) {
      setAllCheckbox({
        checkedList: GetColumns().map(e => e.title),
        indeterminate: false,
        checkAll: true,
      })
    }
  }, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
      checkedList: (e.target.checked && columns.map(e => e.title)) || [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  }, [Checkboxs]);

  useEffect(() => {
    setColumns(GetColumns())
  }, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  return <div className={b()}>
    <Table
      rowKey={(record) => `${record.id}_${record.balance_eur}`}
      className={b()}
      bordered
      dataSource={list}
      scroll={{x: "max-content"}}
      size="middle"
      onChange={(a, b, c) => tableOnChange(a, b, c)}
      onFilter={onFilter}
      pagination={pagination}
      loading={loading}
      columns={columnsFilter}
      rowClassName={() => b('lock')}
      title={() => <TableSettings
        onChangeCheckAll={onChangeCheckAll}
        allChecks={AllCheckbox}
        Checkboxs={AllCheckbox.checkedList}
        TableColumn={() => GetColumns()}
        setCheckbox={onChangeCheckList}
      />}
    />
  </div>
}

export default CardsTable;
