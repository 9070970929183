import ApiService from './ApiService';

export default {
  getCardsPage({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/cards', updatedParams);
  },

  addEditCard(data) {
    return ApiService.post('/card', data);
  },

  deleteEditCard(id) {
    return ApiService.delete(`/card/${id}`);
  },
}
