import { Button, Popconfirm, message } from 'antd';
import React, { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { verifyUser } from '../../../Reducers/users';
import { LoadingOutlined } from '@ant-design/icons';

const VerifyUserEmail = ({ userId, afterUpdate}) => {

  const {payload, isLoading, error} = useSelector(state => state.users.verifyUser, shallowEqual);
  const dispatch = useDispatch();
  console.log(`PAYLOAD: ${error}`)

  const submit = useCallback(() => {
    dispatch(verifyUser(userId)).then(() => {
      message.success('User has been verified!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error(`Can't verify user: ${error === "Locked" ? "Already verified!" : error}`);
    });
  }, [dispatch, afterUpdate]);

  return (
    <Popconfirm title={`Are you sure you want to verify user manually?`}
                      onConfirm={submit}
                      okText="Yes"
                      cancelText="No"
  >
      <Button 
      type="link" 
      size="small"
      icon={isLoading && <LoadingOutlined />}
      >
        Verify User
      </Button>
    </Popconfirm>
  )
}

export default VerifyUserEmail;
