import { Switch, message } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { addEditCard } from '../../../Reducers/cards';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

const LockCardSwitch = ({ rec, locked, id, afterUpdate }) => {

  const dispatch = useDispatch();

  const handleLockCard = useCallback((value) => {
    let card = {
      id: rec.id,
      platform: rec.platform,
      group: rec.group,
      currency: rec.currency,
      name: rec.name,
      basePrice: rec.basePrice,
      locked: value
    }
    dispatch(addEditCard(card))
    .then(() => {
      message.success(value ? 'Card has been locked!' : 'Card has been unlocked!');
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error(value ? 'Failed to lock card!' : 'Failed to unlock card!');
    });
  }, [dispatch]);

  return (
    <Switch 
        className="card-locker_switch"
        defaultValue={locked}
        value={locked}
        onChange={(e) => handleLockCard(e)}
        checkedChildren={<LockOutlined />}
      unCheckedChildren={<UnlockOutlined />}
    />
  )
}

export default LockCardSwitch
